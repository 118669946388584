import React, {
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	Container,
	Form,
	Button,
	ListGroup,
	Badge,
	Row,
	Col,
} from "react-bootstrap";
import LeftNavbar from "../../components/left-navbar";
import AccountBox from "../../components/account-box";
import { ImCheckmark } from "react-icons/im";
import { BsPlusLg } from "react-icons/bs";
import { MdEdit, MdOutlineClear } from "react-icons/md";
import Airtable from "airtable";
import { v4 as uuidv4 } from "uuid";
import { Modal } from "react-bootstrap";
import { Link } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import Layout from "../../components/layout";
import { FaCopy } from "react-icons/fa";
import CountriesList from "../../components/countries-list";

const ReactQuill = React.lazy(() => import("react-quill"));

const CreateEventPage = () => {
	const { user } = useAuth0();

	// <GetRole id={user.sub}/>
	// GetRoleP(user.sub);
	const roles = user["https://register.richmondevents.com/roles"];
	let nsIsEnabled = roles.includes("Admin") || roles.includes("Manager");
	function renderConfirmation(message) {
		const confirmed = window.confirm(message);
		if (confirmed) {
			window.location.href = "/admin/home"; // replace "/" with the desired home page URL
		}
	}

	const defaultMessage1 =
		"We will send you formal confirmation of your booking very shortly.";
	const defaultMessage2 =
		"Thank you for sending us your referrals. I look forward to working with you and if I can be of any further assistance, please contact me.";

	const [selectedTerm, setSelectedTerm] = useState("");
	const [isCancelTerm, setIsCancelTerm] = useState(false);
	const [dataCancelRecords, setDataCancelRecords] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState("");
	const [delegateEmailValid, setDelegateEmailValid] = React.useState(true);
	const [secondaryDelegateEmailValid, setSecondaryDelegateEmailValid] =
		React.useState(true);
	const [forumEmailValid, setForumEmailValid] = React.useState(true);
	const [forumEventRedirect, setForumEventRedirect] = React.useState(true);

	const [languages, setLanguages] = useState([
		{
			id: uuidv4(),
			value: "",
			terms: {
				code: "",
				documentName: "",
				country: "",
				language: "",
				content: "",
			},
			termsCancel: {
				code: "",
				documentName: "",
				country: "",
				language: "",
				content: "",
			},
		},
	]);
	const [currentStep, setCurrentStep] = useState(1);
	const [dataRecords, setDataRecords] = useState([]);
	const [selectedTermCode, setSelectedTermCode] = useState("");
	const [isEdited, setIsEdited] = useState(false);
	const [copied, setCopied] = useState(false);
	const [show, setShow] = useState(false);
	const [selectedTermsContent, setSelectedTermsContent] = useState("");
	const [secondaryManager, setSecondaryManager] = useState(false);
	const [show2, setShow2] = useState(false);

	const [formData, setFormData] = useState({
		// Initialize your form data fields
		eventId: "",

		eventName: "",
		location: "",
		country: "",
		date: "",
		languages: [],

		delegateManagerName: "",
		delegateManagerPosition: "",
		delegateManagerPhone: "",
		delegateManagerEmail: "",

		secondaryDelegateManagerName: "",
		secondaryDelegateManagerPosition: "",
		secondaryDelegateManagerPhone: "",
		secondaryDelegateManagerEmail: "",
		cancellationDate: new Date().toISOString().split("T")[0],
		cancellationFee: "",
		forumEmail: "",
		eventRedirectUrl: "",
		// optionalAdditionalTextToNotificationEmail: "",
		thankYouMessage1: defaultMessage1,
		thankYouMessage2: defaultMessage2,
		cancellationTerms: "",
		terms: "",
	});

	const handleClose = useCallback(() => setShow(false), []);
	const handleShow = useCallback(() => setShow(true), []);
	const handleClose2 = useCallback(() => setShow2(false), []);
	const handleShow2 = useCallback(() => setShow2(true), []);

	useEffect(() => {
		setFormData((prevFormData) => ({
			...prevFormData,
			languages: languages,
		}));
	}, [languages]);

	const handleAddLanguage = () => {
		if (languages.length < 5) {
			const newLanguages = [...languages];
			newLanguages.push({
				id: uuidv4(),
				value: "",
				terms: {
					code: "",
					documentName: "",
					country: "",
					language: "",
					content: "",
				},
				termsCancel: {
					code: "",
					documentName: "",
					country: "",
					language: "",
					content: "",
				},
			});
			setLanguages(newLanguages);
		}
	};

	const handleRemoveLanguage = (id) => {
		const newLanguages = languages.filter((language) => language.id !== id);
		setLanguages(newLanguages);
	};
	const handleCloseModal = () => {
		window.location.href = "/admin/home";

		handleClose2();
	};

	const handleLanguageChange = (id, event) => {
		const newLanguages = languages.map((language) => {
			if (language.id === id) {
				return {
					...language,
					value: event.target.value,
					terms: {
						code: "",
						documentName: "",
						country: "",
						language: "",
						content: "",
					},
					termsCancel: {
						code: "",
						documentName: "",
						country: "",
						language: "",
						content: "",
					},
				};
			}
			return language;
		});
		setLanguages(newLanguages);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch("/.netlify/functions/fetch-terms", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.GATSBY_CREATEINVITE_KEY_1,
					},
				});

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				setDataRecords(data);
				// console.log(data);
			} catch (err) {
				alert(
					"Could not retrieve data. Please wait 30 seconds and refresh the page."
				);
				// console.error(err);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch("/.netlify/functions/fetch-cancel-terms", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.GATSBY_CREATEINVITE_KEY_2,
					},
				});

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				setDataCancelRecords(data);
			} catch (err) {
				alert(
					"Could not retrieve data. Please wait 30 seconds and refresh the page."
				);
				// console.error(err);
			}
		};

		fetchData();
	}, []);

	const handleNextStep = () => {
		setCurrentStep((prevStep) => prevStep + 1);
	};
	const handlePrevStep = () => {
		setCurrentStep((prevStep) => prevStep - 1);
	};

	const handleTermChange = (language, value) => {
		// Update the value in the array of terms using the index from the map
		const updatedTerms = [...formData.languages];
		const languageIndex = updatedTerms.findIndex(
			(item) => item.value === language
		);

		const correctTerm = dataRecords.find(
			(record) => record.id === value
		).fields;

		updatedTerms[languageIndex] = {
			...updatedTerms[languageIndex],
			terms: correctTerm,
			isEdited: false,
		};

		// Update the formData state with the updated terms
		setLanguages(updatedTerms);
		// console.log(updatedTerms);
	};
	const handleCancelTermChange = (language, value) => {
		// Update the value in the array of terms using the index from the map
		const updatedTerms = [...formData.languages];
		const languageIndex = updatedTerms.findIndex(
			(item) => item.value === language
		);

		const correctTerm = dataCancelRecords.find(
			(record) => record.id === value
		).fields;

		updatedTerms[languageIndex] = {
			...updatedTerms[languageIndex],
			termsCancel: correctTerm,
			isEditedCancel: false,
		};

		// Update the formData state with the updated terms
		setLanguages(updatedTerms);
		// console.log(updatedTerms);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "eventRedirectUrl") {
			setForumEventRedirect(e.target.checkValidity());
		}
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};
	const handleEmailChange = (e) => {
		const { name, value } = e.target;
		if (name === "delegateManagerEmail") {
			setDelegateEmailValid(e.target.checkValidity());
		}
		if (name === "secondaryDelegateManagerEmail") {
			setSecondaryDelegateEmailValid(e.target.checkValidity());
		}
		if (name === "forumEmail") {
			setForumEmailValid(e.target.checkValidity());
		}

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Handle form submission
		// console.log(formData);
	};

	const handleStepClick = (step) => {
		setCurrentStep(step);
	};

	const handleButtonClick = (id) => {
		// Update only the content field
		if (selectedTermsContent?.length > 30 && isCancelTerm === false) {
			if (window.confirm("Are you sure you want to save?")) {
				const updatedTerms = [...formData.languages];
				const languageIndex = updatedTerms.findIndex(
					(item) => item.terms.uniqueId === id
				);
				if (updatedTerms[languageIndex].isEdited !== true) {
					updatedTerms[languageIndex] = {
						...updatedTerms[languageIndex],
						terms: {
							...updatedTerms[languageIndex].terms,
							// Update specific fields inside terms here
							content: selectedTermsContent,
							code: `${updatedTerms[languageIndex].terms.code} (edited)`,
						},
						isEdited: true,
					};
				} else {
					updatedTerms[languageIndex] = {
						...updatedTerms[languageIndex],
						terms: {
							...updatedTerms[languageIndex].terms,
							// Update specific fields inside terms here
							content: selectedTermsContent,
						},
					};
				}
				setLanguages(updatedTerms);
				handleClose();
			}
		} else if (selectedTermsContent?.length > 30 && isCancelTerm === true) {
			if (window.confirm("Are you sure you want to save?")) {
				const updatedTerms = [...formData.languages];
				const languageIndex = updatedTerms.findIndex(
					(item) => item.termsCancel.uniqueId === id
				);
				if (updatedTerms[languageIndex].isEditedCancel !== true) {
					updatedTerms[languageIndex] = {
						...updatedTerms[languageIndex],
						termsCancel: {
							...updatedTerms[languageIndex].termsCancel,
							// Update specific fields inside terms here
							content: selectedTermsContent,
							code: `${updatedTerms[languageIndex].termsCancel.code} (edited)`,
						},
						isEditedCancel: true,
					};
				} else {
					updatedTerms[languageIndex] = {
						...updatedTerms[languageIndex],
						termsCancel: {
							...updatedTerms[languageIndex].termsCancel,
							// Update specific fields inside terms here
							content: selectedTermsContent,
						},
					};
				}
				setLanguages(updatedTerms);
				handleClose();
			}
		} else {
			alert("Please enter a value for the content");
		}
	};
	const handleButtonClick2 = async () => {
		const eventUID = uuidv4();
		try {
			if (
				isStepFilled(1) &&
				isStepFilled(2) &&
				delegateEmailValid &&
				secondaryDelegateEmailValid &&
				forumEmailValid &&
				forumEventRedirect &&
				isStepFilled(3) &&
				isStepFilled(4)
			) {
				const checkResponse = await fetch(
					"/.netlify/functions/create-invite-event-create-step-1",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							"x-api-key": process.env.GATSBY_CREATEINVITE_KEY_3,
						},
						body: JSON.stringify({ eventId: formData.eventId }),
					}
				);

				const checkData = await checkResponse.json();
				if (checkData.exists) {
					alert("An invite with this code already exists.");
					return;
				}

				// Create new event
				await fetch("/.netlify/functions/create-invite-event-create-step-2", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.GATSBY_CREATEINVITE_KEY_4,
					},
					body: JSON.stringify({
						formData: {
							code: formData.eventId,
							event: formData.eventName,
							venue: formData.location,
							date: formData.date,
							languages: JSON.stringify(formData.languages),
							country: formData.country,
							dm: formData.delegateManagerName,
							dmPosition: formData.delegateManagerPosition,
							dmTelephone: formData.delegateManagerPhone,
							dmEmail: formData.delegateManagerEmail,
							secondaryDm: formData.secondaryDelegateManagerName,
							secondaryDmPosition: formData.secondaryDelegateManagerPosition,
							secondaryDmTelephone: formData.secondaryDelegateManagerPhone,
							secondaryDmEmail: formData.secondaryDelegateManagerEmail,
							eventCancellationDate: formData.cancellationDate,
							eventCancellationFee: formData.cancellationFee,
							eventForumEmail: formData.forumEmail,
							eventRedirectUrl: formData.eventRedirectUrl,
							thankYouMessage1: formData.thankYouMessage1,
							thankYouMessage2: formData.thankYouMessage2,
							uniqueEventId: eventUID,
						},
						eventUID,
					}),
				});

				// Create event backup
				await fetch("/.netlify/functions/create-invite-event-create-step-3", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.GATSBY_CREATEINVITE_KEY_5,
					},
					body: JSON.stringify({
						formData: {
							code: formData.eventId,
							event: formData.eventName,
							venue: formData.location,
							date: formData.date,
							languages: JSON.stringify(formData.languages),
							country: formData.country,
							dm: formData.delegateManagerName,
							dmPosition: formData.delegateManagerPosition,
							dmTelephone: formData.delegateManagerPhone,
							dmEmail: formData.delegateManagerEmail,
							secondaryDm: formData.secondaryDelegateManagerName,
							secondaryDmPosition: formData.secondaryDelegateManagerPosition,
							secondaryDmTelephone: formData.secondaryDelegateManagerPhone,
							secondaryDmEmail: formData.secondaryDelegateManagerEmail,
							eventCancellationDate: formData.cancellationDate,
							eventCancellationFee: formData.cancellationFee,
							eventForumEmail: formData.forumEmail,
							eventRedirectUrl: formData.eventRedirectUrl,
							thankYouMessage1: formData.thankYouMessage1,
							thankYouMessage2: formData.thankYouMessage2,
							uniqueEventId: eventUID,
						},
						eventUID,
					}),
				});

				// Update the UI or perform any other necessary actions
				handleShow2();
			} else {
				// Alert the user to fill in the required fields
				alert("Please fill in the required fields with valid data.");
			}
		} catch (error) {
			alert(
				"There has been an error with Airtable please wait 30 seconds and try again"
			);
			// console.error("Error with Airtable operation:", error);
		}
	};

	const renderFormStep = () => {
		switch (currentStep) {
			case 1:
				return (
					<div>
						<Form.Group controlId="eventId">
							<Form.Label className="text-med-grey">Event ID</Form.Label>
							<Form.Control
								placeholder="Event ID"
								type="text"
								name="eventId"
								value={formData.eventId}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group>
						{/* <Form.Group className="mt-5" controlId="participationType">
							<Form.Label className="text-med-grey">
								Participation Type
							</Form.Label>
							<Form.Control
								placeholder="Participation type"
								type="text"
								name="participationType"
								value={formData.participationType}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group> */}
						<Form.Group className="mt-5" controlId="eventName">
							<Form.Label className="text-med-grey">Event Name</Form.Label>
							<Form.Control
								placeholder="Event name"
								type="text"
								name="eventName"
								value={formData.eventName}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group>
						<Form.Group className="mt-5" controlId="location">
							<Form.Label className="text-med-grey">Location</Form.Label>
							<Form.Control
								placeholder="Location"
								type="text"
								name="location"
								value={formData.location}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group>
						<div>
							<Form.Group className={`mt-5 `} controlId="eventDate">
								<Form.Label className="text-med-grey">Date</Form.Label>
								<Form.Control
									placeholder="Date"
									type="text"
									name="date"
									value={formData.date}
									onChange={handleChange}
									style={{
										border: "none",
										borderBottom: "1px solid #255DAB",
										borderRadius: "0px",
										color: "#787878",
									}}
								/>
							</Form.Group>
						</div>
						<Form.Group className="mt-5" controlId="country">
							<Form.Label className="text-med-grey">Country</Form.Label>
							<Form.Select
								placeholder="Country"
								name="country"
								value={formData.country === "" ? `Country` : formData.country}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							>
								{/* Add options for the dropdown */}
								<option disabled selected hidden>
									Country
								</option>
								<CountriesList />
							</Form.Select>
						</Form.Group>

						<Form.Group className="mt-5" controlId="language">
							{languages.map((language, index) => (
								<div key={language.id}>
									<Form.Label className="text-med-grey">
										Language {index + 1}
									</Form.Label>
									<Form.Select
										placeholder="Language"
										name="language"
										value={
											language.value === ""
												? `Language-${index + 1}`
												: language.value
										}
										onChange={(event) =>
											handleLanguageChange(language.id, event)
										}
										style={{
											border: "none",
											borderBottom: `1px solid ${
												language.value === "" ? "#ff0000" : "#255DAB"
											}`,
											borderRadius: "0px",
											color: "#787878",
										}}
									>
										{/* Add options for the dropdown */}
										<option disabled selected hidden>
											Language-{index + 1}
										</option>
										<option value="English-UK">English-UK</option>
										<option value="English-US">English-US</option>
										<option value="Swiss-German">Swiss-German</option>
										<option value="Spanish">Spanish</option>
										<option value="Italian">Italian</option>
									</Form.Select>
									{languages.length > 1 && (
										<div className="text-end mt-3">
											<Button
												className="px-3 mb-2 text-white"
												variant="red"
												onClick={() => handleRemoveLanguage(language.id)}
											>
												Remove
											</Button>
										</div>
									)}
								</div>
							))}

							{languages.length < 5 && (
								<div className="text-center mt-4">
									<a
										className="text-decoration-none"
										style={{ cursor: "pointer" }}
										onClick={handleAddLanguage}
									>
										Add additional language
										<BsPlusLg className="ms-3 fs-4" />
									</a>
								</div>
							)}
						</Form.Group>
					</div>
				);
			case 2:
				return (
					<div>
						<h2 className="mb-4 fs-3">Primary delegate manager</h2>

						<Form.Group className="mt-5" controlId="delegateManagerName">
							<Form.Label className="text-med-grey">Name</Form.Label>
							<Form.Control
								placeholder="Name"
								type="text"
								name="delegateManagerName"
								value={formData.delegateManagerName}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group>
						<Form.Group className="mt-5" controlId="delegateManagerPosition">
							<Form.Label className="text-med-grey">Position</Form.Label>
							<Form.Control
								placeholder="Position"
								type="text"
								name="delegateManagerPosition"
								value={formData.delegateManagerPosition}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group>
						<Form.Group className="mt-5" controlId="delegateManagerPhone">
							<Form.Label className="text-med-grey">Phone</Form.Label>
							<Form.Control
								placeholder="Phone"
								type="text"
								name="delegateManagerPhone"
								value={formData.delegateManagerPhone}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group>
						<Form.Group className="mt-5" controlId="delegateManagerEmail">
							<Form.Label className="text-med-grey">Email</Form.Label>
							<Form.Control
								placeholder="Email"
								type="email"
								name="delegateManagerEmail"
								value={formData.delegateManagerEmail}
								required
								onChange={handleEmailChange}
								isInvalid={!delegateEmailValid}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
							<Form.Control.Feedback type="invalid">
								Please enter a valid email address.
							</Form.Control.Feedback>
						</Form.Group>
						{secondaryManager === false && (
							<div className="text-center mt-4">
								<a
									className="text-decoration-none"
									style={{ cursor: "pointer" }}
									onClick={() => setSecondaryManager(true)}
								>
									Add secondary delegate manager{" "}
									<BsPlusLg className="ms-3 fs-4" />
								</a>
							</div>
						)}
						{secondaryManager === true && (
							<div>
								<h2 className="mb-4 mt-5 fs-3">Secondary delegate manager</h2>

								<Form.Group
									className="mt-5"
									controlId="secondaryDelegateManagerName"
								>
									<Form.Label className="text-med-grey">Name</Form.Label>
									<Form.Control
										placeholder="Name"
										type="text"
										name="secondaryDelegateManagerName"
										value={formData.secondaryDelegateManagerName}
										onChange={handleChange}
										style={{
											border: "none",
											borderBottom: "1px solid #255DAB",
											borderRadius: "0px",
											color: "#787878",
										}}
									/>
								</Form.Group>
								<Form.Group
									className="mt-5"
									controlId="secondaryDelegateManagerPosition"
								>
									<Form.Label className="text-med-grey">Position</Form.Label>
									<Form.Control
										placeholder="Position"
										type="text"
										name="secondaryDelegateManagerPosition"
										value={formData.secondaryDelegateManagerPosition}
										onChange={handleChange}
										style={{
											border: "none",
											borderBottom: "1px solid #255DAB",
											borderRadius: "0px",
											color: "#787878",
										}}
									/>
								</Form.Group>
								<Form.Group
									className="mt-5"
									controlId="secondaryDelegateManagerPhone"
								>
									<Form.Label className="text-med-grey">Phone</Form.Label>
									<Form.Control
										placeholder="Phone"
										type="text"
										name="secondaryDelegateManagerPhone"
										value={formData.secondaryDelegateManagerPhone}
										onChange={handleChange}
										style={{
											border: "none",
											borderBottom: "1px solid #255DAB",
											borderRadius: "0px",
											color: "#787878",
										}}
									/>
								</Form.Group>
								<Form.Group
									className="mt-5"
									controlId="secondaryDelegateManagerEmail"
								>
									<Form.Label className="text-med-grey">Email</Form.Label>
									<Form.Control
										placeholder="Email"
										type="email"
										name="secondaryDelegateManagerEmail"
										value={formData.secondaryDelegateManagerEmail}
										onChange={handleEmailChange}
										isInvalid={!secondaryDelegateEmailValid}
										style={{
											border: "none",
											borderBottom: "1px solid #255DAB",
											borderRadius: "0px",
											color: "#787878",
										}}
									/>
									<Form.Control.Feedback type="invalid">
										Please enter a valid email address.
									</Form.Control.Feedback>
								</Form.Group>
							</div>
						)}
					</div>
				);
			case 3:
				return (
					<div>
						<Form.Group className="mt-5" controlId="cancellationDate">
							<Form.Label className="text-med-grey">
								Cancellation Date
							</Form.Label>
							<Form.Control
								placeholder="Cancellation date"
								type="date"
								name="cancellationDate"
								value={formData.cancellationDate}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group>
						<Form.Group className="mt-5" controlId="cancellationFee">
							<Form.Label className="text-med-grey">
								Cancellation Fee
							</Form.Label>
							<Form.Control
								placeholder="Cancellation fee"
								type="text"
								name="cancellationFee"
								value={formData.cancellationFee}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group>
						<Form.Group className="mt-5" controlId="forumEmail">
							<Form.Label className="text-med-grey">
								Forum email for booking form notifications
							</Form.Label>
							<Form.Control
								placeholder="Forum Email"
								type="email"
								name="forumEmail"
								value={formData.forumEmail}
								required
								onChange={handleEmailChange}
								isInvalid={!forumEmailValid}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
							<Form.Control.Feedback type="invalid">
								Please enter a valid email address.
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="mt-5" controlId="eventRedirectUrl">
							<Form.Label className="text-med-grey">
								Event Redirect URL (e.g. https://www.example.com)
							</Form.Label>
							<Form.Control
								placeholder="Event redirect URL (e.g. https://www.example.com)"
								type="url"
								name="eventRedirectUrl"
								isInvalid={!forumEventRedirect}
								value={formData.eventRedirectUrl}
								onChange={handleChange}
								pattern='^(ftp|http|https):\/\/[^ "]+$'
								required
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
							<Form.Control.Feedback type="invalid">
								Please enter a valid url.
							</Form.Control.Feedback>
						</Form.Group>
						{/* <Form.Group
							className="mt-5"
							controlId="optionalAdditionalTextToNotificationEmail"
						>
							<Form.Label className="text-med-grey">
								Optional Additional Text To Notification Email
							</Form.Label>
							<Form.Control
								as="textarea"
								rows={4}
								placeholder="Optional additional text to notification email"
								name="optionalAdditionalTextToNotificationEmail"
								value={formData.optionalAdditionalTextToNotificationEmail}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group> */}
						<Form.Group className="mt-5" controlId="thankYouMessage1">
							<Form.Label className="text-med-grey">
								Thank you message 1 (Other Information step)
							</Form.Label>
							<Form.Control
								as="textarea"
								rows={4}
								placeholder="Thank you message 1"
								name="thankYouMessage1"
								value={formData.thankYouMessage1}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group>
						<div className=" mt-2">
							<a
								className="text-decoration-none"
								style={{ cursor: "pointer", fontSize: "90%" }}
								onClick={() => {
									setFormData((prevFormData) => ({
										...prevFormData,
										thankYouMessage1: defaultMessage1,
									}));
								}}
							>
								Reset to default
							</a>
						</div>
						<Form.Group className="mt-5" controlId="thankYouMessage2">
							<Form.Label className="text-med-grey">
								Thank you message 2 (Thank You step)
							</Form.Label>
							<Form.Control
								as="textarea"
								rows={4}
								placeholder="Thank you message 2"
								name="thankYouMessage2"
								value={formData.thankYouMessage2}
								onChange={handleChange}
								style={{
									border: "none",
									borderBottom: "1px solid #255DAB",
									borderRadius: "0px",
									color: "#787878",
								}}
							/>
						</Form.Group>
						<div className=" mt-2">
							<a
								className="text-decoration-none"
								style={{ cursor: "pointer", fontSize: "90%" }}
								onClick={() => {
									setFormData((prevFormData) => ({
										...prevFormData,
										thankYouMessage2: defaultMessage2,
									}));
								}}
							>
								Reset to default
							</a>
						</div>
					</div>
				);
			case 4:
				return (
					<div>
						{formData.languages.map((language, index) => (
							<div key={language.value}>
								<Row className="mt-5">
									<Col lg={10}>
										<h2 className="mb-3">
											{language.value || (
												<span className="text-red fs-5">
													Language not selected
												</span>
											)}
										</h2>
									</Col>
								</Row>
								<Row>
									<Col lg={10}>
										<Form.Group
											controlId={`cancellation-terms-${language.value}`}
										>
											<Form.Label className="text-med-grey">
												Cancellation Terms
											</Form.Label>
											<Form.Select
												placeholder={`Cancellation terms (${language.value})`}
												name={`cancellation-terms-${language.value}`}
												value={
													language.termsCancel?.code === ""
														? `Cancellation terms (${language.value})`
														: language.termsCancel?.code
												}
												onChange={(e) =>
													handleCancelTermChange(language.value, e.target.value)
												}
												style={{
													border: "none",
													borderBottom: "1px solid #255DAB",
													borderRadius: "0px",
													color: "#787878",
												}}
											>
												<option disabled selected hidden>
													Cancellation terms ({language.value})
												</option>
												{language.termsCancel?.code !== "" && (
													<option hidden>{language.termsCancel?.code}</option>
												)}
												{dataCancelRecords.map((option) => (
													<option value={option.id} key={option.id}>
														{option.fields.code}
													</option>
												))}
											</Form.Select>
										</Form.Group>
									</Col>
									{language.termsCancel?.code !== "" && (
										<Col className="text-center" lg={2}>
											<Button
												className="px-2 pt-0 pb-1 text-white"
												variant="dark-bg"
												onClick={() => {
													setIsCancelTerm(true);
													setSelectedTerm(languages[index].termsCancel);
													setSelectedTermsContent(
														languages[index].termsCancel.content
													);
													handleShow();
												}}
											>
												<MdEdit className="text-white" />
											</Button>
										</Col>
									)}
								</Row>
								<Row className="mt-5">
									<Col lg={10}>
										<Form.Group controlId={`terms-${language.value}`}>
											<Form.Label className="text-med-grey">
												Terms and Conditions
											</Form.Label>
											<Form.Select
												placeholder={`Terms and conditions (${language.value})`}
												name={`terms-${language.value}`}
												value={
													language.terms?.code === ""
														? `Terms and conditions (${language.value})`
														: language.terms?.code
												}
												onChange={(e) =>
													handleTermChange(language.value, e.target.value)
												}
												style={{
													border: "none",
													borderBottom: "1px solid #255DAB",
													borderRadius: "0px",
													color: "#787878",
												}}
											>
												<option disabled selected hidden>
													Terms and conditions ({language.value})
												</option>
												{language.terms?.code !== "" && (
													<option hidden>{language.terms?.code}</option>
												)}
												{dataRecords.map((option) => (
													<option value={option.id} key={option.id}>
														{option.fields.code}
													</option>
												))}
											</Form.Select>
										</Form.Group>
									</Col>
									{language.terms?.code !== "" && (
										<Col className="text-center" lg={2}>
											<Button
												className="px-2 pt-0 pb-1 text-white"
												variant="dark-bg"
												onClick={() => {
													setIsCancelTerm(false);
													setSelectedTerm(languages[index].terms);
													setSelectedTermsContent(
														languages[index].terms.content
													);
													handleShow();
												}}
											>
												<MdEdit className="text-white" />
											</Button>
										</Col>
									)}
								</Row>
							</div>
						))}
					</div>
				);
			case 5:
				return (
					<div>
						<div>
							<div className="px-5">
								<Row className="align-items-center">
									<Col lg={6}>
										<h2 className="fs-5">Event</h2>
									</Col>
									<Col className="text-end" lg={6}>
										<Button
											className="px-2 pt-0 pb-1 text-white"
											variant="dark-bg"
											onClick={() => {
												handleStepClick(1);
											}}
										>
											<MdEdit className="text-white" />
										</Button>
									</Col>
								</Row>
							</div>
							<hr />
							<div className="px-5  mt-4">
								<Row className="align-items-center">
									<Col>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" text-med-grey pb-2 fw-bold mb-0">
													Event Code
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.eventId || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										{/* <Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 fw-bold mb-0">Participation Type</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.participationType || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row> */}
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Event Name
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.eventName || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Event Location
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.location || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Event Country
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.country || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Event Date
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.date || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>

										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Languages
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.languages.map((language, index) => (
														<span key={language.id}>
															{language.value || (
																<span className="text-red">REQUIRED</span>
															)}
															{index !== formData.languages.length - 1 && ", "}
														</span>
													))}
												</p>
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
							<div className="px-5">
								<Row className="align-items-center">
									<Col lg={6}>
										<h2 className="fs-5">Contact(s)</h2>
									</Col>
									<Col className="text-end" lg={6}>
										<Button
											className="px-2 pt-0 pb-1 text-white"
											variant="dark-bg"
											onClick={() => {
												handleStepClick(2);
											}}
										>
											<MdEdit className="text-white" />
										</Button>
									</Col>
								</Row>
							</div>
							<hr />
							<div className="px-5  mt-4">
								<Row className="align-items-center">
									<Col>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Contact Name
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.delegateManagerName || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Contact Position
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.delegateManagerPosition || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Contact Telephone
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.delegateManagerPhone || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Contact Email
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.delegateManagerEmail || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>

										{secondaryManager && (
											<div>
												<Row>
													<Col className="text-end" lg={6}>
														<p className=" pb-2 text-med-grey fw-bold mb-0">
															{" "}
															Secondary Contact Name
														</p>
													</Col>
													<Col lg={6}>
														<p className="pb-2 mb-0">
															{formData.secondaryDelegateManagerName || (
																<span className="text-white">empty</span>
															)}
														</p>
													</Col>
												</Row>
												<Row>
													<Col className="text-end" lg={6}>
														<p className=" pb-2  text-med-grey fw-bold mb-0">
															Secondary Contact Position
														</p>
													</Col>
													<Col lg={6}>
														<p className="pb-2 mb-0">
															{formData.secondaryDelegateManagerPosition || (
																<span className="text-white">empty</span>
															)}
														</p>
													</Col>
												</Row>
												<Row>
													<Col className="text-end" lg={6}>
														<p className=" pb-2 text-med-grey fw-bold mb-0">
															Secondary Contact Telephone
														</p>
													</Col>
													<Col lg={6}>
														<p className="pb-2 mb-0">
															{formData.secondaryDelegateManagerPhone || (
																<span className="text-white">empty</span>
															)}
														</p>
													</Col>
												</Row>
												<Row>
													<Col className="text-end" lg={6}>
														<p className=" pb-2 text-med-grey fw-bold mb-0">
															Secondary Contact Email
														</p>
													</Col>
													<Col lg={6}>
														<p className="pb-2 mb-0">
															{formData.secondaryDelegateManagerEmail || (
																<span className="text-white">empty</span>
															)}
														</p>
													</Col>
												</Row>
											</div>
										)}
									</Col>
								</Row>
							</div>
							<div className="px-5  mt-4">
								<Row className="align-items-center">
									<Col lg={6}>
										<h2 className="fs-5">Details</h2>
									</Col>
									<Col className="text-end" lg={6}>
										<Button
											className="px-2  pt-0 pb-1 text-white"
											variant="dark-bg"
											onClick={() => {
												handleStepClick(3);
											}}
										>
											<MdEdit className="text-white" />
										</Button>
									</Col>
								</Row>
							</div>
							<hr />
							<div className="px-5">
								<Row className="align-items-center">
									<Col>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Event Cancellation Date
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{" "}
													{new Date(
														formData.cancellationDate
													).toLocaleDateString("en-GB") || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Event Cancellation Fee
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.cancellationFee || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Forum email for booking form notifications
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.forumEmail || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Event Redirect URL
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.eventRedirectUrl || (
														<span className="text-red">REQUIRED</span>
													)}
												</p>
											</Col>
										</Row>
										{/* <Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Optional additional text to notification email
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.optionalAdditionalTextToNotificationEmail || (
														<span className="text-white">empty</span>
													)}
												</p>
											</Col>
										</Row> */}
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Thank you message 1 (Other Information step)
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.thankYouMessage1 || (
														<span className="text-white">empty</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col className="text-end" lg={6}>
												<p className=" pb-2 text-med-grey fw-bold mb-0">
													Thank you message 2 (Thank You step)
												</p>
											</Col>
											<Col lg={6}>
												<p className="pb-2 mb-0">
													{formData.thankYouMessage2 || (
														<span className="text-white">empty</span>
													)}
												</p>
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
							<div className="px-5 mt-4">
								<Row className="align-items-center">
									<Col lg={6}>
										<h2 className="fs-5">Terms</h2>
									</Col>
									<Col className="text-end" lg={6}>
										<Button
											className="px-2 pt-0 pb-1 text-white"
											variant="dark-bg"
											onClick={() => {
												handleStepClick(4);
											}}
										>
											<MdEdit className="text-white" />
										</Button>
									</Col>
								</Row>
							</div>
							<hr />
							<div className="px-5">
								{formData.languages.map((language, index) => (
									<Row className="align-items-center mt-4" key={index}>
										<Col>
											<Row>
												<Col lg={6} className="text-end">
													<h3 className="fs-5 mb-3">
														{language.value || (
															<span className="text-red">REQUIRED</span>
														)}
													</h3>
												</Col>
											</Row>
											<Row>
												<Col className="text-end" lg={6}>
													<p className="pb-2 text-med-grey fw-bold mb-0">
														Cancellation terms
													</p>
												</Col>
												<Col lg={6}>
													<p className="pb-2 mb-0">
														{language.termsCancel.code || (
															<span className="text-red">REQUIRED</span>
														)}
													</p>
												</Col>
											</Row>

											<Row>
												<Col className="text-end" lg={6}>
													<p className="pb-2 text-med-grey fw-bold mb-0">
														Terms & conditions
													</p>
												</Col>
												<Col lg={6}>
													<p className="pb-2 mb-0">
														{language.terms.code || (
															<span className="text-red">REQUIRED</span>
														)}
													</p>
												</Col>
											</Row>
										</Col>
									</Row>
								))}
							</div>
						</div>
					</div>
				);

			default:
				return null;
		}
	};

	const renderStepList = () => {
		return (
			<div style={{ display: "flex", justifyContent: "center" }}>
				{[1, 2, 3, 4, 5].map((step) => (
					<div
						key={step}
						style={{
							display: "flex",
							alignItems: "center",
						}}
						className="position-relative"
					>
						<a
							href="#"
							className="text-decoration-none d-block"
							onClick={() => handleStepClick(step)}
						>
							{currentStep === step && (
								<div
									className="text-white fw-bold"
									style={{
										width: "30px",
										height: "30px",
										borderRadius: "50%",
										backgroundColor:
											currentStep === step ? "#255DAB" : "#96B6E1",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									{isStepFilled(step) ? (
										<span style={{ fontSize: "17px" }}>
											<ImCheckmark className="text-white pb-1" />
										</span>
									) : (
										step
									)}
								</div>
							)}
							{currentStep !== step && !isStepFilled(step) && step !== 5 && (
								<div
									className="text-white fw-bold"
									style={{
										width: "30px",
										height: "30px",
										borderRadius: "50%",
										backgroundColor: "#CF3333",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									{isStepFilled(step) ? (
										<span style={{ fontSize: "17px" }}>
											<ImCheckmark className="text-white pb-1" />
										</span>
									) : (
										step
									)}
								</div>
							)}
							{((currentStep !== step && isStepFilled(step)) ||
								(currentStep !== step && step === 5)) && (
								<div
									className="text-white fw-bold"
									style={{
										width: "30px",
										height: "30px",
										borderRadius: "50%",
										backgroundColor: isStepFilled(step) ? "#2DA8A5" : "#96B6E1",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									{isStepFilled(step) ? (
										<span style={{ fontSize: "17px" }}>
											<ImCheckmark className="text-white pb-1" />
										</span>
									) : (
										step
									)}
								</div>
							)}
							<div
								style={{ bottom: "-50px" }}
								className="position-absolute start-0  "
							>
								{step === 1 && (
									<h2 style={{ color: "#787878" }} className="fs-5 ">
										Event
									</h2>
								)}
								{step === 2 && (
									<h2 style={{ color: "#787878" }} className="fs-5 ">
										Contact(s)
									</h2>
								)}
								{step === 3 && (
									<h2 style={{ color: "#787878" }} className="fs-5 ">
										Details
									</h2>
								)}
								{step === 4 && (
									<h2 style={{ color: "#787878" }} className="fs-5 ">
										Terms
									</h2>
								)}
								{step === 5 && (
									<h2 style={{ color: "#787878" }} className="fs-5 ">
										Summary
									</h2>
								)}
							</div>
						</a>
						{step !== 5 && (
							<div
								style={{
									width: "170px",
									height: "4px",
									background: "rgba(0, 0, 0, 0.32)",
								}}
							></div>
						)}
					</div>
				))}
			</div>
		);
	};

	const isStepFilled = (step) => {
		switch (step) {
			case 1:
				return (
					formData.eventId !== "" &&
					formData.eventName !== "" &&
					formData.location !== "" &&
					formData.country !== "" &&
					formData.date !== "" &&
					formData.languages.every((obj) => obj.value !== "")
				);
			case 2:
				return (
					formData.delegateManagerName !== "" &&
					delegateEmailValid &&
					formData.delegateManagerPosition !== "" &&
					formData.delegateManagerPhone !== "" &&
					formData.delegateManagerEmail !== ""
				);
			case 3:
				return (
					formData.cancellationDate !== "" &&
					forumEmailValid &&
					formData.cancellationFee !== "" &&
					formData.forumEmail !== "" &&
					formData.eventRedirectUrl !== ""
				);
			case 4:
				return (
					formData.languages.every((obj) => obj.terms.code !== "") &&
					formData.languages.every((obj) => obj.termsCancel.code !== "")
				);

			case 5:
				return false;
			default:
				return false;
		}
	};
	const isStepFieldFilled = (...fields) => {
		return fields.filter((field) => field !== "").length % 2 === 1;
	};

	const isAllStepFieldsFilled = (step) => {
		switch (step) {
			case 1:
				return (
					isStepFieldFilled(
						formData.eventId,

						formData.eventName,
						formData.location,
						formData.country,
						formData.date
					) || formData.languages.some((obj) => obj.value === "")
				);
			case 2:
				return isStepFieldFilled(
					formData.delegateManagerName,
					formData.delegateManagerPosition,
					formData.delegateManagerPhone,
					formData.delegateManagerEmail
				);
			case 3:
				return isStepFieldFilled(
					formData.cancellationDate,
					formData.cancellationFee,
					formData.forumEmail,
					formData.eventRedirectUrl
				);
			case 4:
				// return isStepFieldFilled(formData.cancellationTerms, formData.terms);
				return (
					formData.languages.some((obj) => obj.terms.code === "") ||
					formData.languages.some((obj) => obj.termsCancel.code === "")
				);
			case 5:
				return false;
			default:
				return false;
		}
	};

	if (nsIsEnabled) {
		return (
			<Layout>
				<GatsbySeo title="Create Invite" language="en" noindex nofollow />
				<section>
					<Modal
						style={{ zIndex: 99999 }}
						size="lg"
						show={show2}
						onHide={handleCloseModal}
						centered
					>
						<Modal.Body className=" p-4" closeButton>
							<div className="position-relative">
								<div className="position-absolute  end-0 top-0 ">
									<MdOutlineClear
										style={{ cursor: "pointer" }}
										className="fs-3"
										onClick={handleCloseModal}
									/>
								</div>
								<div
									style={{ borderRadius: "8px" }}
									className=" bg-white px-4 py-5"
								>
									<div>
										<Row>
											<Col xs={12}>
												<h2 className="text-center fs-4 mb-4">
													Invite Created
												</h2>
												<p className="fs-5 text-center mb-4">
													The invite has now been created. Copy the URL with
													<br /> the button below.
												</p>
												<p className="text-center">
													<span className="fw-bold">URL:</span>{" "}
													{`https://register.richmondevents.com/invite?code=${formData?.eventId}`}
												</p>
												<div className="text-center mt-4">
													<Button
														className="px-5 me-3 py-3 fs-5 text-white"
														variant="dark-bg"
														onClick={async () => {
															await navigator.clipboard.writeText(
																`https://register.richmondevents.com/invite?code=${formData?.eventId}`
															);
															setCopied(true);
														}}
													>
														{copied ? "Copied" : "Copy Url"}{" "}
														<FaCopy className="ms-2" />
													</Button>
													<Button
														className="px-5 py-3 fs-5 text-white"
														variant="dark-bg"
														as={Link}
														to="/admin/home"
													>
														Back to home page
													</Button>
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						style={{ zIndex: 99999 }}
						size="xl"
						show={show}
						onHide={handleClose}
						centered
					>
						<Modal.Body className=" p-5" closeButton>
							<div className="position-relative">
								<div className="position-absolute  start-0 top-0 ">
									<MdOutlineClear
										style={{ cursor: "pointer" }}
										className="fs-4"
										onClick={handleClose}
									/>
								</div>
								<div className="text-center">
									<h3>{selectedTerm.documentName}</h3>
								</div>
								<hr />
								<Row>
									<Col className="text-center">
										<p className="fw-bold pb-1 mb-0">Code:</p>

										<p>{selectedTerm.code}</p>
										<p className="fw-bold mt-4 pb-1 mb-0">Country:</p>
										<p>{selectedTerm.country}</p>
										<p className="fw-bold mt-4 pb-1 mb-0">Language:</p>
										<p>{selectedTerm.language}</p>
									</Col>
								</Row>
								<Row className="justify-content-center">
									<Col lg={9} className="text-center">
										<Suspense fallback={<div>Loading...</div>}>
											{ReactQuill && (
												<div className="py-4" style={{ height: "30rem" }}>
													<ReactQuill
														theme="snow"
														value={selectedTermsContent}
														onChange={setSelectedTermsContent}
														className="h-100   text-black"
													/>
												</div>
											)}
										</Suspense>
									</Col>
								</Row>
								<Row className="justify-content-center">
									<Col lg={9} className="text-end my-5">
										<Button
											className="px-5 text-white"
											variant="dark-bg"
											onClick={() => handleButtonClick(selectedTerm.uniqueId)}
										>
											Save
										</Button>
									</Col>
								</Row>
							</div>
						</Modal.Body>
					</Modal>
					<div style={{ background: "#F8F8F8", minHeight: "100vh" }}>
						<Container className="px-4 pb-5 pt-3 pb-lg-7" fluid>
							<Row className="justify-content-end mb-4">
								<Col lg={2}>
									<AccountBox />
								</Col>
							</Row>
							<Row>
								<Col style={{ minHeight: "100%" }} lg={1}>
									<LeftNavbar />
								</Col>
								<Col lg={11}>
									<div
										style={{ borderRadius: "8px", minHeight: "800px" }}
										className="bg-white p-4 pt-6"
									>
										<Row className="justify-content-center">
											<Col lg={10}>
												{renderStepList()}
												<Form onSubmit={handleSubmit}>
													<Row className="justify-content-center mt-7 mb-5">
														<Col lg={currentStep !== 5 ? 6 : 12}>
															{renderFormStep()}
															<div className="text-center">
																{currentStep !== 1 && (
																	<Button
																		className="px-7 mt-5  me-3 "
																		variant="outline-dark-bg"
																		onClick={handlePrevStep}
																	>
																		Back
																	</Button>
																)}
																{currentStep !== 5 && (
																	<Button
																		className="px-7 mt-5 text-white"
																		variant="dark-bg"
																		onClick={handleNextStep}
																	>
																		Next
																	</Button>
																)}
																{currentStep === 5 && (
																	<Button
																		className="px-4 mt-5 text-white"
																		variant="dark-bg"
																		onClick={handleButtonClick2}
																	>
																		Create delegate invite
																	</Button>
																)}
															</div>
														</Col>
													</Row>
												</Form>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
			</Layout>
		);
	} else {
		return (
			<div>
				<GatsbySeo title="Create Invite" language="en" noindex nofollow />

				<div style={{ background: "#F8F8F8", minHeight: "100vh" }}>
					<Layout>
						{renderConfirmation(
							"You do not have permission to view this page. you will be redirected to the homepage."
						)}
					</Layout>
				</div>
			</div>
		);
	}
};

export default withAuthenticationRequired(CreateEventPage);
