import React from "react";

function CountriesList() {
	const items = [
		"Italy",
		"Spain",
		"Switzerland",
		"United Kingdom",
		"United States",
	];

	return (
		<>
			{items.map((country, index) => (
				<option key={index} value={country}>
					{country}
				</option>
			))}
		</>
	);
}

export default CountriesList;
